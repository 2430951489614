<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              MeStream
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Social the way it should be
          </p>
          <p class="mb-2">
            Create an account
          </p>
        </v-card-text>

        <!-- register form -->
        <v-card-text>
          <v-progress-linear
            v-if="submitted"
            indeterminate
            />
          <v-form ref="signUpForm" :disabled="submitted">
            <v-text-field
              v-model="username"
              :rules="usernameRules"
              outlined
              label="Username"
              placeholder="JohnDoe"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              outlined
              label="Email"
              placeholder="john@example.com"
              class="mb-3"
            ></v-text-field>

            <v-textarea
              v-model="formWhy"
              outlined
              :rules="textAreaRules"
              counter
              label="Why join the alpha?"
              placeholder="Because I am tired of the status quo"
              class="mb-3"
              />

            <!--
            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            -->

            <v-checkbox
              class="mt-1"
              v-model="formTermsAgree"
              :rules="checkboxRules"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a @click="nothingDialog = true">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="primary"
              class="mt-6"
              v-on:click="submitForm"
              :disabled="submitted"
            >
              Sign Up
            </v-btn>
          </v-form>
          <v-progress-linear
            v-if="submitted"
            indeterminate
            />
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Already have an account?
          </span>
          <router-link :to="{ name:'login' }">
            Sign in instead
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2" v-if="socialLink.length > 0">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social link -->
        <v-card-actions class="d-flex justify-center" v-if="socialLink.length > 0">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>

    <!-- dialogs -->
    <message-dialog
        v-model="successfulDialog"
        v-on:OK="successfulDialog = false"
        title="Success!"
    >
      <p>
        Thank you for signing up.
      </p>
      <p>
        We will get back to you shortly!
      </p>
    </message-dialog>

    <message-dialog
        v-model="errorDialog"
        v-on:OK="errorDialog = false"
        title="Uh oh! Error!"
    >
      <p>
        Our apologies. There was an error processing your request.
      </p>
      <p>
        Please try again later, or contact us at <a href="mailto:info@mestream.io">info@mestream.io</a>
      </p>
    </message-dialog>
    <nothing-dialog v-model="nothingDialog" v-on:OK="nothingDialog = false" />

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import MessageDialog from "@/components/MessageDialog";
import NothingDialog from "@/components/NothingDialog";
import {Action, Getter} from "@/store/anonymous/types";
import goTo from 'vuetify/es5/services/goto'

export default {
  components: {NothingDialog, MessageDialog},
  data: () => ({
    errorDialog: false,
    successfulDialog: false,
    nothingDialog: false,
    submitted: false,
    complete: false,
  }),
  computed: {
    schema() {
      return this.$store.getters[`${[Getter.GetSignUpSchema]}`]
    },
    successful() {
      return this.$store.getters[`${[Getter.GetSuccessful]}`]
    }
  },
  watch: {
    successful: function(newVal, oldVal) {
      // when first successful
      if (this.submitted && !oldVal && newVal) {
        this.showSuccessful()
      }
    }
  },
  methods: {
    submitForm: function() {
      // ensure complete is false (i.e. previous submission)
      this.complete = false

      let status = this.$refs.signUpForm.validate()
      if (!status) {
        // TODO: handle error and make the submit button buzz or something

        // delay until classes show up
        setTimeout(() => {
          let minErrorTop = this.computeErrorTop()
          let currentOffset = window.pageYOffset
          // do not scroll down...
          let absMin = Math.min(currentOffset, currentOffset + minErrorTop - 10)
          goTo(absMin)
        }, 500)
        return
      }
      let submitData = {
        "username": this.username,
        "email": this.email,
        "why": this.formWhy,
        "agree": this.formTermsAgree
      }
      this.$store.dispatch(`${[Action.SaveSignUpForm]}`, submitData).catch(
          (err) => {
            this.showError(err)
          }
      )
      this.submitted = true
    },
    showTerms: function() {
      // TODO: Create a terms and conditions dialog
      this.dialog = true
      //this.termsDialog = true
    },
    computeErrorTop: function() {
      // get the invalidated form entries
      let invalid = document.getElementsByClassName('error--text')
      let invalidArr = Array.from(invalid)
      let tops = invalidArr.map(
          x => {
            let rect = x.getBoundingClientRect();
            return rect.top - rect.height
          })
      let minTop = Math.min.apply(null, tops)
      return minTop
    },
    showSuccessful: function() {
      // clear status
      this.successfulDialog = true
      this.$store.dispatch(`${[Action.ClearSubmittedResponse]}`)

      // clear form
      this.$refs.signUpForm.reset()
      this.submitted = false
    },
    showError: function(err) {
      console.log(err)
      this.errorDialog = true
      this.submitted = false
    },

  },
  mounted() {
    this.$store.dispatch(`${[Action.LoadSignUpSchema]}`)
  },
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const email = ref('')
    // const password = ref('')
    const socialLink = []
    const formWhy = ref('')
    const formTermsAgree = ref(false)
    const emailRules = ref([
      v => !!v || 'Required',
      v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'Invalid e-mail.'
      },
      v => (v && v.length >= 5) || 'Minimum 5 characters',
      v => (v && v.length <= 100) || 'Max 100 characters',
    ])

    const textAreaRules = ref(
        [
          v => !!v || 'Required',
          v => (v && v.length >= 5) || 'Minimum 5 characters',
          v => (v && v.length <= 500) || 'Max 500 characters',
        ]
    )

    const usernameRules = ref(
        [
          v => !!v || 'Required',
          v => (v && v.length >= 2) || 'Minimum 2 characters',
          v => (v && v.length <= 30) || 'Max 30 characters',
        ]
    )

    const checkboxRules = ref(
        [
          v => v || 'Required'
        ]
    )

    return {
      isPasswordVisible,
      username,
      email,
      socialLink,
      formWhy,
      formTermsAgree,

      emailRules,
      usernameRules,
      textAreaRules,
      checkboxRules,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

// hack so that text fields do not move on form-validate
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
